import {
  DataList,
  FormField,
  FormTextBlock,
  Input,
  Popup,
  RadioButtonProps,
  RadioButtons,
} from "@faraday-gitlab/bpfd-portal";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { FieldErrors, FieldValues, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { usePlanner } from "../../../context/PlannerContext";
import useTrackEarlierOrLaterOption from "../../../hooks/planner/useTrackEarlierOrLaterOption";
import useTrackExchangeOption from "../../../hooks/planner/useTrackExchangeOption";
import useTrackHighLowOption from "../../../hooks/planner/useTrackHighLowOption";
import { TPlannerSchema } from "../../../hooks/schemas/plannerSchema";
import { UseMaximumExchangeAow } from "../../../lib/enum";
import { Employment } from "../../../lib/types";
import { calculateMaximumAow } from "../../../react-query/mutations";
import { PortalLanguage } from "../../../utils/languageLoader";
import { retiringSoonResourceLink, svbLink, toeslagenLink } from "../../../utils/pensionLinks";
import { getPlannerBridgingPayload } from "../../../utils/plannerPayload";
import { handleExpiredToken, RetryCallback } from "../../../utils/handleExpiredToken";

interface Props {
  isRetrieved: boolean;
}

const AowBridgingOption = ({ isRetrieved }: Props) => {
  const { exchangeAfterCalculate, isCalculated, planner, isPending: isPendingCalculate } = usePlanner() ?? {};
  const { retirement, employment, scenario } = planner ?? {};
  const {
    getValues,
    setValue,
    watch,
    control,
    setError,
    handleSubmit,
    trigger,
    formState: { errors, touchedFields, isDirty },
  } = useFormContext();

  const isEarlierOrLaterChanges = useTrackEarlierOrLaterOption();
  const isExchangeOptionChanges = useTrackExchangeOption();
  const isHighLowOptionChange = useTrackHighLowOption();
  const intl = useIntl();
  const locale = intl.locale;
  const aowBridgingSelectedKey = "retirementOption.bridgingOption.isSelected";
  const isMaxExchangeAowKey = "retirementOption.bridgingOption.isMaxExchangeAow";
  const exchangeAowAmountKey = "retirementOption.bridgingOption.bridgingAmount";
  const maxExchangeAowAmountKey = "retirementOption.bridgingOption.maxExchangeAowAmount";
  const retirementSelectedKey =
    "retirementOption.earlierOrLaterRetirementOptions.isEarlierOrLaterRetirementSelected";
  const exchangeSelectedKey = "retirementOption.exchangeOption.isSelected";
  const highLowSelectedKey = "retirementOption.highLowLowHigh.isSelected";

  const currentOP = retirement?.exchange?.currentOP ?? 0;
  const afterExchangeOP =
    exchangeAfterCalculate?.afterExchangeOp ?? retirement?.exchange?.afterExchangeOP ?? 0;
  const currentAOW = 0;
  const typedErrors: FieldErrors<TPlannerSchema> = errors;
  const isExchangeAowAmountValid = typedErrors?.retirementOption?.bridgingOption?.bridgingAmount;
  const exchangeAowAmountOptions: RadioButtonProps[] = [
    UseMaximumExchangeAow.YES,
    UseMaximumExchangeAow.NO,
  ].map((option) => ({
    checked: option === UseMaximumExchangeAow.YES,
    id: option,
    label: <FormattedMessage id={`utils.${option.slice(0, -3)}`} />,
    value: option,
  }));
  const watchedExchangeAOW =
    !!watch(exchangeAowAmountKey) && !isExchangeAowAmountValid ? watch(exchangeAowAmountKey) : "0";
  const afterExchangeAOW =
    locale === PortalLanguage.NL
      ? parseFloat(watchedExchangeAOW.replace(/\./g, "").replace(",", "."))
      : parseFloat(watchedExchangeAOW.replace(/,/g, ""));
  const maxExchangeAowAmount = parseFloat(getValues(maxExchangeAowAmountKey));

  const [maximumAowOptions, setMaximumAowOptions] = useState(exchangeAowAmountOptions);
  const [isDisabledAowAmountInput, setIsDisabledAowAmountInput] = useState(true);
  const [maxAowAmount, setMaxAowAmount] = useState(maxExchangeAowAmount);
  const [info, setInfo] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [firstTimeShowExchangeAmount, setFirstTimeShowExchangeAmount] = useState<boolean>(
    Boolean(scenario?.isMaxExchangeAow === false && scenario?.bridgingAmount && isCalculated)
  );
  const [firstTimeGetMaxAfterRetrieve, setFirstTimeGetMaxAfterRetrieve] = useState<boolean>(isRetrieved);

  const [
    watchedAowBridgingSelected,
    watchedRetirementSelected,
    watchedExchangeSelected,
    watchedHighLowSelected,
    watchedIsMaxExchangeAow,
    watchedExchangeAowAmountKey,
  ] = watch([
    aowBridgingSelectedKey,
    retirementSelectedKey,
    exchangeSelectedKey,
    highLowSelectedKey,
    isMaxExchangeAowKey,
    exchangeAowAmountKey,
  ]);

  const [exchangeAmountScenario, setExchangeAmountScenario] = useState<string | undefined>(
    watchedExchangeAowAmountKey
  );

  const isChangesAfterAowSelected =
    watchedAowBridgingSelected &&
    (isEarlierOrLaterChanges || isExchangeOptionChanges || isHighLowOptionChange);

  const { mutateAsync } = useMutation({
    mutationFn: calculateMaximumAow,
    onError: async (postError, dataForm): Promise<void> => {
      handleExpiredToken(postError, mutateAsync as RetryCallback, dataForm);
      setError(exchangeAowAmountKey, { message: postError?.message });
    },
  });

  const onSubmit = useCallback(
    async (dataForm: FieldValues) => {
      const response = await mutateAsync(
        getPlannerBridgingPayload(dataForm, employment ?? ({} as Employment))
      );
      setMaxAowAmount(response?.planner?.maxExchangeBridging);
    },
    [mutateAsync, employment]
  );

  const handleChangeMaximumAowOption = (updatedOption: RadioButtonProps[]) => {
    setMaximumAowOptions(updatedOption);
  };

  const onClickHandler = () => {
    setInfo((prevState) => !prevState);
  };

  const validateRetirementOptions = useCallback(async () => {
    const results = await Promise.all([
      trigger("retirementOption.earlierOrLaterRetirementOptions"),
      trigger("retirementOption.highLowLowHigh"),
      trigger("retirementOption.exchangeOption"),
    ]);

    return results.find((item) => !item) === undefined;
  }, [trigger]);

  useEffect(() => {
    if (!isChanged) return;
    const getNewMaxAowAmount = async () => {
      validateRetirementOptions();
      const isValid = await validateRetirementOptions();
      const shouldFetchNewAow = maxExchangeAowAmount <= 0 && watchedAowBridgingSelected;
      const allowSubmit = isValid && shouldFetchNewAow;
      if (allowSubmit) {
        handleSubmit(onSubmit)();
      }
    };
    getNewMaxAowAmount();
    setIsChanged(false);
  }, [
    onSubmit,
    handleSubmit,
    maxExchangeAowAmount,
    trigger,
    watchedAowBridgingSelected,
    isChanged,
    validateRetirementOptions,
  ]);

  useEffect(() => {
    const getMaxBridgingAmountAfterRetrieve = async () => {
      if (!firstTimeGetMaxAfterRetrieve) return;
      const isValid = await validateRetirementOptions();
      const dataForm = getValues();
      if (isValid) {
        await onSubmit(dataForm);
      }
      setFirstTimeGetMaxAfterRetrieve(false);
    };

    getMaxBridgingAmountAfterRetrieve();
  }, [firstTimeGetMaxAfterRetrieve, getValues, onSubmit, validateRetirementOptions]);

  useEffect(() => {
    const isFieldsChanged = watchedRetirementSelected || watchedExchangeSelected || watchedHighLowSelected;
    if (isFieldsChanged) {
      setIsChanged(true);
    }
  }, [watchedRetirementSelected, watchedExchangeSelected, watchedHighLowSelected]);

  useEffect(() => {
    if (!maxAowAmount) return;
    const isMaxAmountSelected =
      watchedIsMaxExchangeAow?.find((item: RadioButtonProps) => item.checked).value ===
      UseMaximumExchangeAow.YES;
    const currentExchangeValue = getValues(exchangeAowAmountKey);
    setValue(maxExchangeAowAmountKey, maxAowAmount);
    setValue(
      exchangeAowAmountKey,
      intl.formatNumber(isMaxAmountSelected ? maxAowAmount : currentExchangeValue, {
        maximumFractionDigits: 2,
      }),
      {
        shouldValidate: true,
      }
    );
  }, [maxAowAmount, setValue, intl, watchedIsMaxExchangeAow, getValues]);

  useEffect(() => {
    if (!watchedIsMaxExchangeAow) return;

    const selectedMaximumAowOption = watchedIsMaxExchangeAow?.find(
      (option: RadioButtonProps) => option.checked
    );
    const isTouched = touchedFields?.retirementOption?.bridgingOption?.bridgingAmount;
    if (selectedMaximumAowOption?.value === UseMaximumExchangeAow.YES) {
      setIsDisabledAowAmountInput(true);
      setValue(exchangeAowAmountKey, intl.formatNumber(maxAowAmount ?? 0, { minimumFractionDigits: 2 }), {
        shouldValidate: isTouched,
        shouldTouch: true,
      });
      setFirstTimeShowExchangeAmount(false);
    }

    if (selectedMaximumAowOption?.value === UseMaximumExchangeAow.NO) {
      setIsDisabledAowAmountInput(false);
      if (!firstTimeShowExchangeAmount) {
        setValue(exchangeAowAmountKey, "");
      }
      setExchangeAmountScenario(undefined);
    }
  }, [
    watchedIsMaxExchangeAow,
    firstTimeShowExchangeAmount,
    maxAowAmount,
    setValue,
    intl,
    exchangeAmountScenario,
    touchedFields?.retirementOption?.bridgingOption?.bridgingAmount,
  ]);

  useEffect(() => {
    if (isChangesAfterAowSelected && isDirty) {
      setValue(aowBridgingSelectedKey, false);
    }
  }, [isChangesAfterAowSelected, setValue, isDirty]);

  useEffect(() => {
    const setDefaultMaxExchangeAowAmount = () => {
      if (watchedIsMaxExchangeAow || !watchedAowBridgingSelected) return;
      setValue(isMaxExchangeAowKey, maximumAowOptions, { shouldValidate: false });
    };

    setDefaultMaxExchangeAowAmount();
  }, [watchedIsMaxExchangeAow, maximumAowOptions, setValue, watchedAowBridgingSelected]);

  useEffect(() => {
    if (isPendingCalculate) {
      setFirstTimeShowExchangeAmount(true);
    }
  }, [isPendingCalculate]);

  return (
    <>
      <FormTextBlock
        body={<FormattedMessage id="planner.bridging.aow-text" />}
        className="col-span-12"
        heading={<FormattedMessage id="planner.bridging.aow" />}
        showIcon
        onIconClick={onClickHandler}
      />
      <Popup
        open={info}
        body={
          <FormattedMessage
            id="planner.exchange.for-more-info-icon"
            values={{
              link: (
                <Link to={retiringSoonResourceLink} target="_blank">
                  <FormattedMessage id="planner.exchange.altText-link" />
                </Link>
              ),
            }}
          />
        }
        onClose={onClickHandler}
        popupVariant="information"
      />
      <FormTextBlock
        body={
          <FormattedMessage
            id="planner.bridging.aow-and-highlow-text"
            values={{
              surchargesLink: (
                <Link to={toeslagenLink} target="_blank">
                  <FormattedMessage id="planner.surrender.link.toeslagen" />
                </Link>
              ),
              svbLink: (
                <Link to={svbLink} target="_blank">
                  <FormattedMessage id="planner.surrender.link.svb" />
                </Link>
              ),
            }}
          />
        }
        className="col-span-12"
        heading=""
      />
      <DataList
        className="col-span-12"
        columnVisibility={[true, true]}
        dataRows={[
          {
            header: "",
            index: "0",
            inputData: [
              {
                index: "1",
                label: intl.formatMessage({ id: "planner.exchange.op" }),
                object1: {
                  strikethrough: false,
                  value: intl.formatNumber(currentOP, { minimumFractionDigits: 2 }),
                },
                object2: {
                  strikethrough: false,
                  value: intl.formatNumber(afterExchangeOP, { minimumFractionDigits: 2 }),
                },
              },
              {
                index: "2",
                label: intl.formatMessage({ id: "planner.bridging.aow" }),
                object1: {
                  strikethrough: false,
                  value: intl.formatNumber(currentAOW, { minimumFractionDigits: 2 }),
                },
                object2: {
                  strikethrough: false,
                  value: intl.formatNumber(afterExchangeAOW, { minimumFractionDigits: 2 }),
                },
              },
            ],
          },
        ]}
        itemHeaders={{
          header1: "",
          header2: <FormattedMessage id="planner.exchange.current" />,
          header3: <FormattedMessage id="planner.exchange.after-exchange" />,
        }}
        totalVisibleColumns={3}
      />
      <FormField
        className="col-span-12"
        id={isMaxExchangeAowKey}
        labelText={<FormattedMessage id="planner.bridging.maximum-exchange-aow" />}>
        <RadioButtons
          name={isMaxExchangeAowKey}
          options={maximumAowOptions}
          control={control}
          onChange={handleChangeMaximumAowOption}
        />
      </FormField>
      <FormField
        className="col-span-12"
        id={exchangeAowAmountKey}
        labelText={<FormattedMessage id="planner.exchange.amount" />}>
        <Input
          id={exchangeAowAmountKey}
          name={exchangeAowAmountKey}
          placeholder={`€ ${intl.formatNumber(0, { minimumFractionDigits: 2 })}`}
          control={control}
          disabled={isDisabledAowAmountInput}
        />
      </FormField>
    </>
  );
};

export default AowBridgingOption;
