import {
  Button,
  Checkbox,
  FormImportantMessage,
  FormPopup,
  FormTextBlock,
  GenericTextBlock,
} from "@faraday-gitlab/bpfd-portal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fragment, ReactNode, SetStateAction, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAuthentication } from "../../../../context/AuthenticatedContext";
import { usePlanner } from "../../../../context/PlannerContext";
import useExternalWindow, { RedirectUrls } from "../../../../hooks/useExternalWindow";
import useScenarioTexts from "../../../../hooks/useScenarioTexts";
import { ExchangeType, MYDATA_ACTIVE_KEY } from "../../../../lib/enum";
import { AcceptancePayload, RetirementChoices } from "../../../../lib/types";
import {
  processAcceptedChoices,
  processAcceptedChoicesPartner,
  updatePartnerAcceptance,
} from "../../../../react-query/mutations";
import { HREF } from "../../../../routes/routes";
import { getUuid } from "../../../../utils/uuid";
import ThankYouMessage from "../../../atoms/ThankYouMessage";
import AcceptanceUserFinancialInfo from "../../../molecules/plannerAcceptance/AcceptanceUserFinancialInfo";
import useCountries from "../../../../hooks/useCountries";

import "./AcceptancePopup.css";
import { isNumber } from "../../../../utils/number";
import { Link } from "react-router-dom";
import { toeslagenLink, svbLink } from "../../../../utils/pensionLinks";
import { handleExpiredToken, RetryCallback } from "../../../../utils/handleExpiredToken";
import { plannersKeys } from "../../../../react-query/query-keys";

enum agreementStatus {
  AGREED = "agreed",
  APPROVED = "approved",
  AGREE = "agree",
}

interface AcceptancePopupProps {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
  payload: AcceptancePayload | undefined;
}

const AcceptancePopup: React.FC<AcceptancePopupProps> = ({ showPopup, setShowPopup, payload }) => {
  const queryClient = useQueryClient();
  const user = useAuthentication()?.user;
  const { person, relation, scenario } = usePlanner()?.planner || {};
  const { countriesByUserLng } = useCountries();
  const countriesOptions = countriesByUserLng.map((item) => ({
    value: item.countryCode,
    id: item.countryCode,
    label: item.countryName,
  }));
  const { openLoginWindow, externalPopup, loginResponse, setLoginResponse } = useExternalWindow(
    HREF.LOGIN_PARTNER
  );
  const { fullyText, partlyText, surrender, hllh, oppp, bridging } = useScenarioTexts(
    payload ?? ({} as AcceptancePayload)
  );

  const [isConditionsChecked, setIsConditionsChecked] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [hasPartnerSubmitted, setHasPartnerSubmitted] = useState(false);
  const [isBsnError, setIsBsnError] = useState(false);
  const [isAcceptedByPartner, setIsAcceptedByPartner] = useState(!!scenario?.isPartnerDigitalSigned);
  const [isAcceptedByUser, setIsAcceptedByUser] = useState(!!scenario?.isParticipantDigitalSigned);
  const [totalAcceptance, setTotalAcceptance] = useState(false);

  const hasPartner = user?.partnerBsn;
  const partnerAcceptance = isAcceptedByPartner ? agreementStatus.APPROVED : agreementStatus.AGREE;
  const userAcceptance = isAcceptedByUser ? agreementStatus.AGREED : agreementStatus.AGREE;
  const isWindowOpen = externalPopup !== null;
  const approvalStatus = loginResponse?.approved;
  const approvalTrackingCode = loginResponse?.state;
  const isApproved = approvalStatus && approvalTrackingCode === loginResponse.state && !isAcceptedByPartner;
  const hasExchangeEntitlementsAmount =
    isNumber(scenario?.exchangeEntitlementsAmount) && scenario?.exchangeEntitlementsAmount > 0;
  const showAcceptByPartner =
    hasPartner && payload?.exchangeEntitlementsOption === ExchangeType.PPOP && hasExchangeEntitlementsAmount;
  const hasBankAccount = !!(
    payload?.retirementChoices?.bankAccount || person?.personalBankAccount?.bankAccount
  );
  const isSurrender = payload?.surrenderAmount;

  const { mutate: mutatePartnerLogin, reset: resetPartnerLogin } = useMutation({
    mutationFn: updatePartnerAcceptance,
    onSuccess: (data: { redirectUrls: SetStateAction<RedirectUrls | undefined>; state: string }) => {
      openLoginWindow(data);
    },
    onError: async (postError: unknown): Promise<void> => {
      console.log("postError", postError);
      resetPartnerLogin();
    },
  });

  const { mutateAsync: processParticipantChoices, error: postErrorAcceptance } = useMutation({
    mutationFn: processAcceptedChoices,
    onSuccess: () => {
      setTotalAcceptance(
        isAcceptedByUser && (!showAcceptByPartner || (showAcceptByPartner && isAcceptedByPartner))
      );
      queryClient.invalidateQueries({
        queryKey: plannersKeys.all,
      });
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, processParticipantChoices as RetryCallback, data);
    },
  });

  const { mutateAsync: processChoicesPartner, error: postErrorAcceptancePartner } = useMutation({
    mutationFn: processAcceptedChoicesPartner,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: plannersKeys.all,
      });
      setTotalAcceptance(isAcceptedByPartner && isAcceptedByUser);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, processChoicesPartner as RetryCallback, data);
    },
  });

  const plannerRetirementOptions = [
    { text: surrender, id: "surrender" },
    { text: partlyText, id: "partly" },
    { text: fullyText, id: "fully" },
    { text: hllh, id: "hllh" },
    { text: bridging, id: "bridging" },
    { text: oppp, id: "oppp" },
  ].filter((item) => item.text) as { text: ReactNode; id: string }[];

  const choicesPayload = useCallback(
    ({ payload, isPartnerSigning }: { payload?: AcceptancePayload; isPartnerSigning: boolean }) => {
      const { retirementChoices } = payload ?? {};

      if (retirementChoices?.personId) {
        return {
          ...retirementChoices,
          partnerId: isPartnerSigning ? retirementChoices.partnerId : null,
          disabled: undefined,
        };
      }

      const existingCountryCode = person?.personalBankAccount?.countryCode ?? 0;
      const existingBankLand = !person?.personalBankAccount?.isBankAccountEU
        ? countriesOptions.find((country) => country.value === existingCountryCode.toString())?.label
        : "";

      return {
        ...(person?.personalBankAccount?.bankAccount && {
          bankAccount: {
            isBankAccountEU: person?.personalBankAccount?.isBankAccountEU,
            bankAccountNumber: person?.personalBankAccount?.bankAccount,
            bankName: person?.personalBankAccount?.bankName,
            bankBicCode: person?.personalBankAccount?.bankBicCode,
            bankAddress: person?.personalBankAccount?.bankAddress,
            bankLocation: person?.personalBankAccount?.locationBank,
            bankCountryName: existingBankLand,
          },
        }),
        personId: getUuid() ?? "",
        taxExemption: person?.hasTaxException,
        partnerId: isPartnerSigning ? relation?.partnerId : null,
      } as RetirementChoices;
    },
    [person, relation, countriesOptions]
  );

  const handleAcceptance = (cb: () => void) => {
    if (!isConditionsChecked) {
      setIsCheckboxError(true);
      return;
    }
    setIsCheckboxError(false);
    cb();
  };

  const handlePartnerAcceptance = () => {
    if (hasPartner && !isWindowOpen) {
      mutatePartnerLogin();
    }
  };

  const handleUserAcceptance = () => {
    const choicePayload = choicesPayload({ payload, isPartnerSigning: false });
    processParticipantChoices(choicePayload);
    setIsConditionsChecked(false);
    setIsAcceptedByUser(true);
  };

  useEffect(() => {
    if (loginResponse && !hasPartnerSubmitted) {
      setHasPartnerSubmitted(true);
    }

    if (hasPartnerSubmitted && isApproved) {
      const choicePayload = choicesPayload({ payload, isPartnerSigning: true });
      setIsAcceptedByPartner(true);
      processChoicesPartner(choicePayload);
      setLoginResponse(undefined);
      setIsConditionsChecked(false);
    }
  }, [
    hasPartnerSubmitted,
    processChoicesPartner,
    loginResponse,
    isApproved,
    setLoginResponse,
    choicesPayload,
    payload,
  ]);

  useEffect(() => {
    if (hasPartnerSubmitted && isApproved && isBsnError) {
      setIsBsnError(false);
    }

    if (hasPartnerSubmitted && !isApproved && !isAcceptedByPartner) {
      setIsBsnError(true);
    }
  }, [hasPartnerSubmitted, isAcceptedByPartner, isApproved, isBsnError]);

  const confirmWarnText = () => {
    let text = <FormattedMessage id="planner.acceptance.confirm-warn-default" />;
    if (hasPartner && payload?.exchangeEntitlementsOption === ExchangeType.PPOP) {
      text = <FormattedMessage id="planner.acceptance.oppp-confirm" />;
    }
    return text;
  };

  const popupTitle = () => {
    if (payload?.indicationEndEmployment) {
      return <FormattedMessage id="planner.acceptance.popup-title-end-employ" />;
    }
    return <FormattedMessage id="planner.acceptance.popup-title" />;
  };

  return (
    <FormPopup
      ariaLabelCloseIcon="Sluiten"
      open={showPopup}
      onClose={() => setShowPopup(false)}
      preventBackdropClick>
      <div className="">
        <FormTextBlock
          className="pb-0"
          ariaLabelIconButton="informatie"
          heading={!totalAcceptance && popupTitle()}
          body={
            totalAcceptance ? (
              <ThankYouMessage
                body={
                  <>
                    <p className="my-2 block">
                      <FormattedMessage id="retirement-choice.surrender.thank-you" />
                    </p>
                    {!hasBankAccount && (
                      <p className="my-2">
                        <FormattedMessage
                          id="planner.acceptance.thank-you-link-message"
                          values={{
                            link: (
                              <Link to={`/mydata?activeKey=${MYDATA_ACTIVE_KEY.BANK_ACCOUNT}`}>
                                <FormattedMessage id="utils.link" />
                              </Link>
                            ),
                          }}
                        />
                      </p>
                    )}
                  </>
                }
              />
            ) : (
              <>
                <div>
                  {isBsnError && (
                    <FormImportantMessage
                      body={<FormattedMessage id={"error.bsn-validation-fail"} />}
                      className="flex w-full pb-4"
                    />
                  )}
                  {(postErrorAcceptance?.message || postErrorAcceptancePartner?.message) && (
                    <FormImportantMessage
                      body={<FormattedMessage id="error.request" />}
                      className="flex w-full pb-4"
                    />
                  )}
                  {isSurrender && (
                    <FormImportantMessage
                      body={
                        <FormattedMessage
                          id="planner.surrender.body-text"
                          values={{
                            link1: (
                              <Link to={toeslagenLink} target="_blank">
                                <FormattedMessage id="planner.link.toeslagen" />
                              </Link>
                            ),
                            link2: (
                              <Link to={svbLink} target="_blank">
                                <FormattedMessage id="planner.link.svb" />
                              </Link>
                            ),
                          }}
                        />
                      }
                      className="flex w-full pb-4"
                    />
                  )}

                  <GenericTextBlock
                    size="s"
                    content={
                      <>
                        {plannerRetirementOptions?.length > 0 && (
                          <Fragment>
                            <FormattedMessage id="planner.acceptance.choose" />
                            <ul>
                              {plannerRetirementOptions.map((option) => (
                                <li key={option.id} className="mb-1">
                                  {option.text}
                                </li>
                              ))}
                            </ul>
                          </Fragment>
                        )}
                        <div className="mt-2">
                          <AcceptanceUserFinancialInfo
                            bankAccount={payload?.retirementChoices?.bankAccount}
                            disabled={payload?.retirementChoices?.disabled}
                            taxExemption={payload?.retirementChoices?.taxExemption}
                          />
                        </div>
                        {confirmWarnText()}
                      </>
                    }
                  />
                </div>
                <Checkbox
                  id="default-checkbox"
                  label={<FormattedMessage id="planner.acceptance.by-signing" />}
                  feedbackMessage={<FormattedMessage id="planner.partner-acceptance-sign-error" />}
                  onChange={() => {
                    if (!isWindowOpen) setIsConditionsChecked(!isConditionsChecked);
                  }}
                  error={isCheckboxError}
                  checked={isConditionsChecked}
                />
                {showAcceptByPartner && (
                  <GenericTextBlock
                    className="positive-mb-1 negative-mt-1"
                    size="s"
                    content={<FormattedMessage id="planner.partner-acceptance-exchange" />}
                  />
                )}
              </>
            )
          }
        />
        <div className="buttons-row">
          {!totalAcceptance && (
            <>
              <div className="cancel-button">
                <Button
                  text={<FormattedMessage id="utils.cancel" />}
                  ariaLabel={<FormattedMessage id="utils.cancel" />}
                  onClick={() => setShowPopup(false)}
                  arrowPosition="left"
                  size="md"
                  type="button"
                  target="_self"
                  variant="primary"
                />
              </div>
              {showAcceptByPartner && (
                <div className="partner-button">
                  <Button
                    text={
                      isAcceptedByPartner ? (
                        <FormattedMessage id={`utils.approved-by-partner`} />
                      ) : (
                        <FormattedMessage id={`utils.${partnerAcceptance}-by-partner`} />
                      )
                    }
                    ariaLabel={<FormattedMessage id={`utils.${partnerAcceptance}-by-partner`} />}
                    onClick={() => handleAcceptance(() => handlePartnerAcceptance())}
                    disabled={isAcceptedByPartner || isWindowOpen}
                    icon={isAcceptedByPartner ? "checkmark" : undefined}
                    size="md"
                    type="submit"
                  />
                </div>
              )}
              <div>
                <Button
                  text={
                    isAcceptedByUser ? (
                      <FormattedMessage id={`utils.approved`} />
                    ) : (
                      <FormattedMessage id={`utils.${userAcceptance}`} />
                    )
                  }
                  ariaLabel={<FormattedMessage id={`utils.${userAcceptance}`} />}
                  onClick={() => handleAcceptance(() => handleUserAcceptance())}
                  disabled={isAcceptedByUser}
                  icon={isAcceptedByUser ? "checkmark" : undefined}
                  size="md"
                  type="submit"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </FormPopup>
  );
};

export default AcceptancePopup;
