import React from "react";
import { DataTableOverview } from "@faraday-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import {
  Item,
  TableHeader,
} from "@faraday-gitlab/bpfd-portal/dist/app/components/organisms/dataTableOverview/DataTableOverview.types";

export interface MyActionDataTableProps {
  title?: string;
  data: Item[];
}

const MyActionDataTable: React.FC<MyActionDataTableProps> = ({ title, data }) => {
  const header = React.useMemo<TableHeader[]>(() => {
    return [
      {
        index: 0,
        title: <FormattedMessage id="my.action.case-number" />,
      },
      {
        index: 1,
        title: <FormattedMessage id="my.action.subject" />,
      },
      {
        index: 2,
        title: <FormattedMessage id="my.action.status" />,
      },
    ];
  }, []);
  return <DataTableOverview variation="actions" heading={title} tableHeader={header} dataItems={data} />;
};

export default MyActionDataTable;
