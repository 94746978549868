import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueryTokenRefresh } from "../hooks/useQueryTokenRefresh";
import { calculatePlanner, deleteScenario, savePlanner } from "../react-query/mutations";
import { getPlanner } from "../react-query/queries";
import {
  Planner,
  PlannerCalculatePayload,
  PlannerUpdatePayload,
  RetirementCondition,
  Scenario,
  UnfilteredScenario,
} from "../lib/types";
import { handleExpiredToken, RetryCallback } from "../utils/handleExpiredToken";
import { plannersKeys, togglesKeys } from "../react-query/query-keys";
import { invalidateMultipleQueries } from "../react-query/query-utils";

type ExchangeAfterCalculate = Planner["retirement"]["exchange"] & {
  afterExchangePp: number;
  afterExchangeOp: number;
  maxExchangePp: number;
  maxExchangeOp: number;
};

interface PlannerContextProps {
  planner: Planner | undefined;
  isLoading: boolean;
  isFetching: boolean;
  calculate: (data: PlannerCalculatePayload) => Promise<void>;
  isPending: boolean;
  isCalculateSuccess: boolean;
  errorCalculate: Error | null;
  resetCalculate: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  saveSalaryHours: UseMutateAsyncFunction<any, Error, PlannerUpdatePayload, unknown>;
  isSavePending: boolean;
  isSaveSuccess: boolean;
  errorSave: Error | null;
  resetSaveFields: () => void;
  situations: Planner["situations"];
  scenario: Scenario | null;
  retiredCondition: RetirementCondition;
  deleteScenarioAsync: UseMutateAsyncFunction;
  isDeletePending: boolean;
  isCalculated: boolean;
  exchangeAfterCalculate?: ExchangeAfterCalculate;
}

interface PlannerProviderProps {
  children: ReactNode;
}

export const filterUnusedAttributes = (scenario: UnfilteredScenario | null): Scenario | null => {
  if (!scenario) return null;
  const {
    lumpSumAmount: _lumpSumAmount,
    indicationMaxLumpSum: _indicationMaxLumpSum,
    dateLumpSum: _dateLumpSum,
    ...rest
  } = scenario;

  return rest;
};

export const PlannerContext = createContext<PlannerContextProps | undefined>(undefined);

export const PlannerProvider = ({ children }: PlannerProviderProps) => {
  const queryClient = useQueryClient();
  // planner situations array is only used for default values
  // actual situations will come from calculate responses.
  const {
    data: planner,
    error: errorPlanner,
    isLoading,
    isFetching,
    refetch: refetchPlanner,
  } = useQuery({
    queryKey: plannersKeys.all,
    queryFn: getPlanner,
    select: (data) => data.planner,
  });

  const [retiredCondition, setRetiredCondition] = useState<RetirementCondition>({
    allowSubmitChoice: false,
    hasRetirementCase: false,
  });
  const [situations, setSituations] = useState<Planner["situations"]>([]);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [exchangeAfterCalculate, setExchangeAfterCalculate] = useState<ExchangeAfterCalculate>();

  useEffect(() => {
    const areDifferent = planner?.situations !== situations;
    if (planner?.situations && situations?.length === 0 && areDifferent) {
      setSituations(planner.situations);
    }
  }, [situations, planner]);

  const {
    mutateAsync: saveSalaryHours,
    isPending: isSavePending,
    error: errorSave,
    reset: resetSaveFields,
  } = useMutation({
    mutationFn: savePlanner,
    onSuccess: (data) => {
      if (data.planner.situations) {
        setSituations(data.planner.situations);
        setIsSaveSuccess(true);
      }
      queryClient.invalidateQueries({
        queryKey: plannersKeys.all,
      });
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, saveSalaryHours as RetryCallback, data);
    },
  });

  const {
    mutateAsync: calculate,
    isPending,
    isSuccess: isCalculateSuccess,
    error: errorCalculate,
    reset: resetCalculate,
  } = useMutation({
    mutationFn: calculatePlanner,
    onSuccess: async (res) => {
      if (!isCalculated) {
        setIsCalculated(true);
      }
      setRetiredCondition({
        allowSubmitChoice: res.planner.allowSubmitChoice,
        hasRetirementCase: res.planner.hasRetirementCase,
      });
      setSituations(res.planner.situations);
      setExchangeAfterCalculate(res.planner.retirement.exchange);
      invalidateMultipleQueries([plannersKeys.all, togglesKeys.all]);
    },
    onError: async (postError, data): Promise<void> => {
      handleExpiredToken(postError, calculate as RetryCallback, data);
    },
  });

  const { mutateAsync: deleteScenarioAsync, isPending: isDeletePending } = useMutation({
    mutationFn: deleteScenario,
    onMutate: () => {
      resetSaveFields();
    },
    onSuccess: async () => {
      invalidateMultipleQueries([plannersKeys.all, togglesKeys.all]);
    },
    onError: async (postError): Promise<void> => {
      handleExpiredToken(postError, deleteScenarioAsync as RetryCallback);
    },
  });

  useQueryTokenRefresh(errorPlanner, refetchPlanner);

  const contextValue = useMemo(
    () => ({
      planner,
      isLoading,
      isFetching,
      calculate,
      isPending,
      isCalculateSuccess,
      errorCalculate,
      resetCalculate,
      saveSalaryHours,
      isSavePending,
      isSaveSuccess,
      errorSave,
      resetSaveFields,
      situations,
      scenario: planner?.scenario,
      retiredCondition,
      deleteScenarioAsync,
      isDeletePending,
      isCalculated,
      exchangeAfterCalculate,
    }),
    [
      planner,
      calculate,
      errorCalculate,
      resetCalculate,
      isLoading,
      isFetching,
      isPending,
      isCalculateSuccess,
      saveSalaryHours,
      isSavePending,
      isSaveSuccess,
      errorSave,
      resetSaveFields,
      situations,
      retiredCondition,
      deleteScenarioAsync,
      isDeletePending,
      isCalculated,
      exchangeAfterCalculate,
    ]
  );

  return <PlannerContext.Provider value={contextValue}>{children}</PlannerContext.Provider>;
};

export const usePlanner = (): PlannerContextProps | undefined => {
  const context = useContext(PlannerContext);
  return context;
};
