import { Person, Preferences } from "../../../lib/types";
import MyDataCorrespondenceSettings from "../../molecules/myData/MyDataCorrespondenceSettings";
import MyDataCorrespondenceAddress from "../../molecules/myData/MyDataCorrespondenceAddress";
import StandardGridColumn from "../../templates/StandardGridColumn";

interface Props {
  preferences: Preferences;
  user: Person;
}

const MyDataSecondTab: React.FC<Props> = ({ preferences, user }) => {
  return (
    <>
      <StandardGridColumn>
        <MyDataCorrespondenceSettings preferences={preferences} />
      </StandardGridColumn>
      <StandardGridColumn>
        <MyDataCorrespondenceAddress preferences={preferences} user={user} />
      </StandardGridColumn>
    </>
  );
};

export default MyDataSecondTab;
