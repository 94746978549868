import { Accordion, AccordionItem, FormSubmit, Text } from "@faraday-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { TPlannerSchema, usePlannerSchema } from "../../../hooks/schemas/plannerSchema";
import { EARLIER_LATER_FULLY } from "../../../lib/constants";
import { ParticipationStatus, SituationCategory } from "../../../lib/enum";
import { EmploymentAndRetirementRenamed, PlannerCalculatePayload, Retirement } from "../../../lib/types";
import formatEmploymentToAPI from "../../../utils/formatEmploymentToAPI";
import formatRetirementToAPI from "../../../utils/formatRetirementToAPI";
import formatScenarioToForm from "../../../utils/formatScenarioToForm";
import formatSituationPayload from "../../../utils/formatSituationToAPI";
import { limitAge } from "../../../utils/plannerCalendar";
import { renameKeys } from "../../../utils/renameKeys";
import { getRetirementLimits } from "../../../utils/retirement";
import PlannerEmploymentOptions from "./PlannerEmploymentOptions";
import PlannerOtherOptions from "./PlannerOtherOptions";
import PlannerRetirementOptions from "./PlannerRetirementOptions";
import useScreenWidth, { Breakpoints } from "../../../hooks/useScreenWidth";

interface PlannerOptionsProps {
  isRetrieved: boolean;
  scrollToView: () => void;
}

const PlannerOptions: React.FC<PlannerOptionsProps> = ({ isRetrieved, scrollToView }) => {
  const intl = useIntl();
  const screenWidth = useScreenWidth();
  const {
    planner,
    calculate,
    isPending,
    isCalculateSuccess,
    errorCalculate,
    scenario,
    isSaveSuccess,
    situations,
  } = usePlanner() || {};

  const isActive = planner?.participation?.status === ParticipationStatus.ACTIVE;
  const hasDisability = !!planner?.person?.disabled;
  const employment = planner?.employment;
  const hasEndEmployment = employment?.isEndOfEmployment;
  const isSuccessfulCalculate = !!employment?.salary && isCalculateSuccess;

  const [isEmploymentActive, setIsEmploymentActive] = useState<boolean>(isActive);
  const [isEmploymentExpended, setIsEmploymentExpended] = useState<boolean>(false);

  const shouldBeActive = !isEmploymentActive && (isSaveSuccess || isSuccessfulCalculate);

  // dateOfBirth is checked in Planner.tsx and retirementDate is calculated with dateOfBirth
  // 0 is used to avoid @typescript-eslint/no-non-null-assertion error
  const retirementDate = planner?.participation?.defaultRetirementDate ?? "0";
  const dateOfBirth = planner?.person?.dateOfBirth ?? "0";
  const retirement = useMemo(() => planner?.retirement ?? ({} as Retirement), [planner?.retirement]);
  const aowAgeInMonths =
    situations?.find((item) => item.category === SituationCategory.AOW)?.ageInMonths ?? 0;

  const defaultFormValues = useMemo(
    () => ({
      employmentOptions: {
        workingTimeOption: {
          isSelected: false,
        },
        earningSalaryOption: {
          isSelected: false,
        },
        quittingWorkOption: {
          isSelected: false,
        },
      },
      retirementOption: {
        surrenderOptions: {
          isSurrenderSelected: false,
          isRefrainFromSurrenderSelected: false,
        },
        earlierOrLaterRetirementOptions: {
          isEarlierOrLaterRetirementSelected: false,
          fullyOrPartial: {
            id: EARLIER_LATER_FULLY.toString(),
            label: intl.formatMessage({ id: "utils.fully" }),
            value: EARLIER_LATER_FULLY,
          },
        },
        exchangeOption: {
          isSelected: false,
        },
        bridgingOption: {
          isSelected: false,
          maxExchangeAowAmount: retirement?.maxExchangeBridging ?? 0,
        },
        highLowLowHigh: {
          isSelected: false,
        },
      },
    }),
    [intl, retirement]
  );

  const methods = useForm({
    mode: "onChange",
    resolver: zodResolver(
      usePlannerSchema({
        isActive,
        hasDisability,
        aowAgeInMonths,
        limitAge: limitAge(new Date(dateOfBirth), new Date(retirementDate)),
        retirement: retirement,
        retirementYear: new Date(retirementDate).getFullYear(),
        retirementOptionsLimits: {
          ...getRetirementLimits({
            retirement,
            participantDOB: dateOfBirth,
          }),
        },
      })
    ),
    defaultValues: defaultFormValues,
  });
  const {
    formState: { isSubmitting, errors },
    reset,
  } = methods;

  const onSubmit = (submitData: TPlannerSchema) => {
    if (calculate) {
      const renamedKeys = renameKeys(submitData) as EmploymentAndRetirementRenamed;
      const payload: PlannerCalculatePayload = {
        hours: Number(employment?.hours),
        salary: Number(employment?.salary),
        situations: formatSituationPayload(planner?.situations),
        employment: formatEmploymentToAPI(renamedKeys.employment),
        retirement: formatRetirementToAPI(renamedKeys.retirement),
      };

      calculate(payload);
    }
  };

  // if click yes on scenario popup, reset form default values to scenario values
  useEffect(() => {
    if (
      isRetrieved &&
      scenario &&
      employment &&
      defaultFormValues !== formatScenarioToForm(scenario, employment, intl, planner.retirement)
    ) {
      const resetValues = formatScenarioToForm(scenario, employment, intl, planner.retirement);
      reset(resetValues);
    }
  }, [defaultFormValues, employment, intl, isRetrieved, reset, scenario, planner?.retirement]);

  useEffect(() => {
    if (isCalculateSuccess && screenWidth < Breakpoints.LG) {
      scrollToView();
    }
  }, [isCalculateSuccess, screenWidth, scrollToView]);

  useEffect(() => {
    if (shouldBeActive) {
      setIsEmploymentActive(true);
      setIsEmploymentExpended(true);
    }
  }, [isEmploymentActive, isSaveSuccess, shouldBeActive]);

  const isObjectNotEmpty = (obj: object): boolean => {
    return Object.keys(obj).length > 0;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Accordion
          keyExtractor={(item) => item.key}
          className="gap-y-2 flex flex-col"
          accordionItems={[
            isEmploymentActive && (
              <AccordionItem
                isAccordionOpen={isEmploymentExpended}
                onAccordionIsOpenChange={(open) => {
                  setIsEmploymentExpended(open);
                }}
                id="employmentOptions"
                key="1"
                title={<FormattedMessage id="planner.employment-heading-section" />}
                content={<PlannerEmploymentOptions />}
              />
            ),
            !hasEndEmployment && (
              <AccordionItem
                id="retirementOptions"
                key="2"
                title={<FormattedMessage id="planner.retirement-heading-section" />}
                content={<PlannerRetirementOptions isRetrieved={isRetrieved} />}
              />
            ),
            <AccordionItem
              id="otherOptions"
              key="3"
              title={<FormattedMessage id="planner.other-heading-section" />}
              content={<PlannerOtherOptions />}
            />,
          ].filter(Boolean)}
        />
        <div className="pt-4">
          <FormSubmit
            showBorder={false}
            buttons={[
              {
                ariaLabel: "submit",
                size: "md",
                text: <FormattedMessage id="planner.calculate" />,
                type: "submit",
                disabled: !!isSubmitting || isPending || hasEndEmployment,
              },
            ]}
            errorMessage={
              // margin-top accounts for border-radius of button above the text
              <div style={{ margin: "0.75rem auto 10px auto" }}>
                {isObjectNotEmpty(errors) ? (
                  <FormattedMessage id={"planner.calculate-error"} />
                ) : (
                  errorCalculate?.message
                )}
              </div>
            }
          />
        </div>
        <Text
          as="span"
          fontFamily="tertiary"
          fontStyle="normal"
          size="quote"
          type="primary-sm-button"
          weight="normal"
          wordBreak>
          <FormattedMessage id="planner.calculate-info" />
        </Text>
      </form>
    </FormProvider>
  );
};

export default PlannerOptions;
