import { Person } from "../../../lib/types";
import MyDataPaymentInformation from "../../molecules/myData/MyDataPaymentInformation";

interface Props {
  user: Person;
}

const MyDataThirdTab: React.FC<Props> = ({ user }) => {
  const tailwindClasses = "lg:col-span-6 lg:col-start-4 col-span-12";

  return (
    <div className={tailwindClasses}>
      <MyDataPaymentInformation user={user} />
    </div>
  );
};

export default MyDataThirdTab;
