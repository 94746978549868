import React from "react";
import { FormattedMessage } from "react-intl";
import { CardHeader, PageLayout } from "@faraday-gitlab/bpfd-portal";
import MyActionDataTable from "../organisms/dataTable/MyActionDataTable";
import { useIntlMessage } from "../../hooks/useIntlMessage";
import { useQuery } from "@tanstack/react-query";
import { getOutstandingRequests } from "../../react-query/queries";
import { OutstandingAction, OutstandingRequest } from "../../lib/types";
import Loading from "../organisms/Loading";
import { useQueryTokenRefresh } from "../../hooks/useQueryTokenRefresh";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { EMyActionOption, OpenActions } from "../../lib/enum";
import { useNavigate } from "react-router-dom";
import {
  MYACTION_OPEN_ACTIONS,
  MYACTION_OPEN_ACTIONS_LABEL,
  MYACTION_OPTION_LINK,
} from "../../lib/constants";
import { removeLastUnderscoreAndAfter } from "../../utils/removeLastUnderscoreAndAfter";
import { CardContent } from "@faraday-gitlab/bpfd-portal/dist/app/components/atoms/card/Card.types";
import { Item } from "@faraday-gitlab/bpfd-portal/dist/app/components/organisms/dataTableOverview/DataTableOverview.types";
import FlexContainer from "../templates/FlexContainer";
import { casesKeys } from "../../react-query/query-keys";

const Actions = () => {
  const navigate = useNavigate();
  const intlMessage = useIntlMessage();

  const { toggles } = useAuthentication() || {};
  const {
    data: cases,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: casesKeys.all,
    queryFn: getOutstandingRequests,
  });

  useQueryTokenRefresh(error, refetch);

  const formatActions = React.useMemo<CardContent[]>(
    () => [
      ...(toggles?.isRetireEarlyOrLater
        ? [
            {
              index: EMyActionOption.EARLY_LATE,
              text: <FormattedMessage id="my.action.retire.early.title" />,
            },
          ]
        : []),

      ...(toggles?.isRequestValueTransfer
        ? [
            {
              index: EMyActionOption.VALUE_TRANSFER,
              text: <FormattedMessage id="my.action.transfer.value.title" />,
            },
          ]
        : []),
      ...(toggles?.isStartCohabitation
        ? [
            {
              index: EMyActionOption.COHABIT,
              text: <FormattedMessage id="my.action.cohabit.title" />,
            },
          ]
        : []),

      ...(toggles?.isEndCohabitation
        ? [
            {
              index: EMyActionOption.END_COHABIT,
              text: <FormattedMessage id="my.action.end-cohabit.title" />,
            },
          ]
        : []),
    ],
    [toggles]
  );
  const formatStatusRequests = React.useMemo<Item[]>(() => {
    const result: OutstandingRequest[] = cases?.statusRequests ?? [];

    return result.map((item) => ({
      id: item.caseId,
      caseNumber: item.caseNumber,
      request: item.recordType,
      status: item.status,
    }));
  }, [cases?.statusRequests]);

  const formatOpenActions = React.useMemo<CardContent[]>(() => {
    const result: OutstandingAction[] = cases?.openActions ?? [];

    const toHumanReadable = (date: Date) => {
      const month = intlMessage(
        "utils.month." + date.toLocaleString("default", { month: "long" }).toLowerCase()
      );
      const day = date.getDate();
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    };

    return result.map((item, i) => ({
      index: (item.type + "_" + i) as unknown as number, //Faraday type is only number but a string is needed
      text: <FormattedMessage id={`${MYACTION_OPEN_ACTIONS_LABEL[item.type as OpenActions]}`} />,
      dateText: toHumanReadable(new Date(item.createDate)),
    }));
  }, [cases?.openActions, intlMessage]);

  const handleNavigateActions = (key: EMyActionOption) => {
    navigate(MYACTION_OPTION_LINK[key]);
  };

  const handleNavigateOpenActions = (index: string | number) => {
    const cleanIndex = removeLastUnderscoreAndAfter(index.toString());
    navigate(MYACTION_OPEN_ACTIONS[cleanIndex as OpenActions]);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageLayout>
      <div className="bg-gradient-mint-blue-light flex flex-col flex-grow">
        <section className="bg-white">
          <FlexContainer>
            <CardHeader
              text={<FormattedMessage id="my.action.outstanding.action.title" />}
              withDate
              contents={formatOpenActions}
              onClick={handleNavigateOpenActions}
            />
            <CardHeader
              key="actions"
              className="pt-15"
              contents={formatActions}
              onClick={(index) => handleNavigateActions(index as EMyActionOption)}
              text={<FormattedMessage id="my.action.request.title" />}
            />
          </FlexContainer>
        </section>

        <MyActionDataTable
          title={intlMessage("my.action.outstanding.request.title")}
          data={formatStatusRequests}
        />
      </div>
    </PageLayout>
  );
};

export default Actions;
