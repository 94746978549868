import { Button, DataList, InfoToggle, Popup, SituationOverviewPanel } from "@faraday-gitlab/bpfd-portal";

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { AcceptancePayload, Amount, RetirementChoices, Situation } from "../../../lib/types";
import { SitName, SituationCategory } from "../../../lib/enum";
import { format } from "../../../utils/formatNumber";
import { getDateByTotalMonths } from "../../../utils/plannerCalendar";
import AdditionalQuestions from "../../molecules/plannerAdditionalQuestion/AdditionalQuestions";
import AcceptancePopup from "./acceptancePopup/AcceptancePopup";
import { DataRow } from "@faraday-gitlab/bpfd-portal/dist/app/components/molecules/dataList/DataList.types";

const ageHandler = (ageInMonths: number) => {
  return (
    <FormattedMessage
      id="utils.years-months"
      values={{ years: Math.floor(ageInMonths / 12), months: ageInMonths % 12 }}
    />
  );
};

const getAOWDate = ({ situations, dateOfBirth }: { situations?: Situation[]; dateOfBirth?: string }) => {
  if (!dateOfBirth || !situations) return undefined;
  const aowAgeInMonths = situations?.find((item) => item.category === SituationCategory.AOW)?.ageInMonths;
  const aowAgeInMonthsDefault =
    situations?.find((item) => item.category === SituationCategory.DEFAULT)?.ageInMonths ?? 0;
  const aowDate = getDateByTotalMonths(new Date(dateOfBirth), aowAgeInMonths ?? aowAgeInMonthsDefault);
  return aowDate;
};

const getRetirementDate = ({
  retirementStartYear,
  retirementStartMonth,
  dateOfBirth,
}: {
  retirementStartYear?: number;
  retirementStartMonth?: number;
  dateOfBirth?: string;
}) => {
  if (!dateOfBirth || !retirementStartMonth || !retirementStartYear) return undefined;
  const dob = new Date(dateOfBirth);
  const retirementDate = new Date(
    dob.getFullYear() + retirementStartYear,
    dob.getMonth() + retirementStartMonth,
    1
  );
  return retirementDate;
};

const getSurrenderAmount = (situations?: Situation[]) =>
  situations
    ?.find((s) => s.category === SituationCategory.PENSION)
    ?.amounts.find((a) => a.name === SitName.SURRENDER)?.calculatedGross;

const dataRows = (curr: Situation[], intlMessage: (id: string) => string): DataRow[] => {
  return curr
    .filter(Boolean)
    .sort((a, b) => a.ageInMonths - b.ageInMonths)
    .map((sit, i) => {
      const sitIndex = i.toString();
      let ageAmountRows = sit.amounts;
      const showTotal = sit.showTotal;
      const aow = ageAmountRows.find((a) => a.name === SitName.AOW);
      const pension = ageAmountRows.find((a) => a.name === SitName.PENSION);
      const surrender = ageAmountRows.find((a) => a.name === SitName.SURRENDER);
      const total = ageAmountRows.find((a) => a.name === SitName.TOTAL);

      const isLast = (e: Amount) => ageAmountRows.indexOf(e) === ageAmountRows.length - 1;
      const moveToBottom = (e: Amount) =>
        ageAmountRows.push(ageAmountRows.splice(ageAmountRows.indexOf(e), 1)[0]);

      if (surrender && pension) {
        ageAmountRows = ageAmountRows.filter((a) => a.name !== SitName.PENSION);
      }

      if (pension && !isLast(pension)) {
        moveToBottom(pension);
      }

      if (aow && !isLast(aow)) {
        moveToBottom(aow);
      }

      if (showTotal && total && !isLast(total)) {
        moveToBottom(total);
      }

      if (showTotal && total === undefined) {
        const safeNumber = (n: number | null) => {
          if (n === null) return null;
          return Number(n?.toFixed(2));
        };
        ageAmountRows.push({
          name: SitName.TOTAL,
          currentGross: safeNumber(sit.totalCurrentGross),
          currentNet: safeNumber(sit.totalCurrentNet),
          calculatedGross: safeNumber(sit.totalCalculatedGross),
          calculatedNet: safeNumber(sit.totalCalculatedNet),
          isTotal: showTotal,
          referenceValue: null,
        });
      }

      return {
        header: ageHandler(sit.ageInMonths),
        index: sitIndex,
        inputData: ageAmountRows.map((row, index) => {
          const indexAmount = `${sitIndex}${index}`;
          const isTotal = row.isTotal;
          const isCalcG = !!row.calculatedGross;
          const isCalcN = !!row.calculatedNet;

          const currGross = format(row.currentGross);
          const currNet = format(row.currentNet);
          const calcGross = format(row.calculatedGross);
          const calcNet = format(row.calculatedNet);

          const optionString = row.optionInfo ? ` (${row.optionInfo})` : "";

          return {
            index: indexAmount,
            label: row.name !== "" ? intlMessage(`planner.situation.${row.name}`) + optionString : "",
            object1: currGross ? { strikethrough: isCalcG, value: currGross, isTotal: isTotal } : null,
            object2: currNet ? { strikethrough: isCalcN, value: currNet, isTotal: isTotal } : null,
            object3: calcGross ? { strikethrough: false, value: calcGross, isTotal: isTotal } : null,
            object4: calcNet ? { strikethrough: false, value: calcNet, isTotal: isTotal } : null,
          };
        }),
      };
    });
};

const PlannerSituation: React.FC = () => {
  const { planner, situations, isCalculateSuccess, retiredCondition, scenario, isFetching } =
    usePlanner() || {};
  const intlMessage = useIntlMessage();

  const [showPartnerPopup, setShowPartnerPopup] = useState(false);
  const [netGrossPopup, setNetGrossPopup] = useState(false);
  const [isNet, setIsNet] = useState(false);
  const [showAdditionalQuestion, setShowAdditionalQuestion] = useState(false);
  const [retirementChoices, setRetirementChoices] = useState<RetirementChoices>();

  const dateOfBirth = planner?.person.dateOfBirth;
  const hasActiveRetirementCase = retiredCondition?.hasRetirementCase;
  const isDisabledSaveButton =
    isFetching ||
    !isCalculateSuccess ||
    planner?.employment?.isEndOfEmployment ||
    !retiredCondition?.allowSubmitChoice ||
    !planner?.scenario ||
    (scenario?.indicationEndEmployment && planner?.retirement?.exchange?.maxExchangeOP === 0);

  const handleNetGrossPopup = () => {
    setNetGrossPopup((netGrossPopup) => !netGrossPopup);
  };

  const handleNetGrossAmount = () => {
    setIsNet((isNet) => !isNet);
  };

  const handleSaveToActions = () => {
    if (!hasActiveRetirementCase) {
      setShowAdditionalQuestion(true);
      return;
    }
    setShowPartnerPopup(true);
  };

  const handleAdditionalQuestionClose = (plannerConfirmChoices?: RetirementChoices) => {
    if (plannerConfirmChoices?.personId) {
      setRetirementChoices(plannerConfirmChoices);
      setShowPartnerPopup(true);
    }
    setShowAdditionalQuestion(false);
  };

  const acceptancePayload = {
    ...scenario,
    aowRetirementDate: getAOWDate({ situations, dateOfBirth }),
    retirementDate: getRetirementDate({
      retirementStartMonth: scenario?.retirementStartMonth,
      retirementStartYear: scenario?.retirementStartYear,
      dateOfBirth,
    }),
    surrenderAmount: getSurrenderAmount(situations),
    retirementChoices,
  } as AcceptancePayload;

  if (!situations) return <SituationOverviewPanel header={<FormattedMessage id="error.gross-net" />} />;

  return (
    <>
      <SituationOverviewPanel
        header={<FormattedMessage id="planner.current-situation" />}
        infoToggles={[
          <InfoToggle
            key="some-toggle-id"
            onIconClick={handleNetGrossPopup}
            onChange={handleNetGrossAmount}
            icon="information"
            iconVisible
            id="some-toggle-id"
            label={<FormattedMessage id="planner.gross-net" />}
          />,
        ]}
        buttons={[
          <Button
            size="sm"
            className="my-2"
            onClick={handleSaveToActions}
            text={<FormattedMessage id="planner.save-to-actions" />}
            disabled={isDisabledSaveButton}
            key="save-to-actions"
          />,
        ]}
        dataList={
          <DataList
            itemHeaders={{
              header1: <FormattedMessage id="utils.age" />,
              header2: <FormattedMessage id="planner.current" />,
              header3: <FormattedMessage id="utils.net" />,
              header4: <FormattedMessage id="planner.calculated" />,
              header5: <FormattedMessage id="utils.net" />,
            }}
            dataRows={dataRows(structuredClone(situations), intlMessage)}
            columnVisibility={[true, isNet, true, isNet]}
            totalVisibleColumns={isNet ? 5 : 3}
          />
        }
      />
      <Popup
        open={netGrossPopup}
        heading={<FormattedMessage id="planner.gross-net" />}
        body={<FormattedMessage id="planner.gross-net-info" />}
        onClose={handleNetGrossPopup}
        popupVariant="information"
      />
      {showAdditionalQuestion && (
        <AdditionalQuestions showPopup={showAdditionalQuestion} onClose={handleAdditionalQuestionClose} />
      )}
      {showPartnerPopup && (
        <AcceptancePopup
          showPopup={showPartnerPopup}
          setShowPopup={setShowPartnerPopup}
          payload={acceptancePayload}
        />
      )}
    </>
  );
};

export default PlannerSituation;
