import PartnerForm from "../forms/myData/partnerform/PartnerForm";
import { FormTextBlock } from "@faraday-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { HREF } from "../../routes/routes";
import EndPartnerForm, { EndPartnerFormProps } from "../forms/myData/partnerform/EndPartnerForm";
import Loading from "../organisms/Loading";
import { useEffect } from "react";
import StandardFormWrapper from "../templates/StandardFormWrapper";

const PartnerDetail: React.FC = () => {
  const navigate = useNavigate();
  const { toggles, user, isLoading } = useAuthentication() || {};

  const isAuthorized: boolean = (toggles?.isEndCohabitation || toggles?.isStartCohabitation) ?? true;
  const baseUser: EndPartnerFormProps = {
    firstName: user?.partnerFirstName ?? "",
    lastName: user?.partnerLastName ?? "",
    middleName: user?.partnerMiddleName ?? "",
    bsn: user?.partnerBsn ?? "",
    dob: user?.partnerDateOfBirth ?? "",
    gender: user?.partnerGender ?? "",
    startDate: user?.relationshipStartDate ?? "",
  };

  useEffect(() => {
    if (!isLoading && !isAuthorized) {
      navigate(HREF.NOTFOUND);
    }
  }, [isAuthorized, isLoading, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StandardFormWrapper>
      <FormTextBlock heading={<FormattedMessage id="page-partner.title" />} />
      {toggles?.isEndCohabitation ? <EndPartnerForm {...baseUser} /> : <PartnerForm />}
    </StandardFormWrapper>
  );
};

export default PartnerDetail;
