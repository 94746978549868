import { Person } from "../../../lib/types";
import { MaritalStatus } from "../../../lib/enum";
import MyDataUserData from "../../molecules/myData/MyDataUserData";
import MyDataContactDetails from "../../molecules/myData/MyDataContactDetails";
import MyDataPartnerDetails from "../../molecules/myData/MyDataPartnerDetails";
import StandardGridColumn from "../../templates/StandardGridColumn";

interface Props {
  user: Person;
}

const MyDataFirstTab: React.FC<Props> = ({ user }) => {
  const hasPartner = user.maritalStatus !== null && user.maritalStatus !== MaritalStatus.CHILD;

  return (
    <>
      <StandardGridColumn>
        <MyDataUserData user={user} />
      </StandardGridColumn>
      <StandardGridColumn>
        <MyDataContactDetails user={user} />
      </StandardGridColumn>
      {hasPartner && (
        <StandardGridColumn>
          <MyDataPartnerDetails user={user} />
        </StandardGridColumn>
      )}
    </>
  );
};

export default MyDataFirstTab;
